import React, { Component } from 'react';
import './AddedToCartPopup.css';



class AddedToCartPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cartPositionX: 0,
            cartPositionY: 0,
            isAnimating: false
        };
    }

    componentDidMount() {
        const navWrapper = document.querySelector('.menu_nav-wrapper');
        const rect = navWrapper.getBoundingClientRect();
        this.setState({ cartPositionX: rect.x,  cartPositionY: rect.y  });
    }

    componentDidUpdate(prevProps) {
        if (this.props.showPopup !== prevProps.showPopup) {
            if(!prevProps.showPopup) {
                this.setState({ isAnimating: true });

                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;

                const blockWidth = this.block.offsetWidth;
                const blockHeight = this.block.offsetHeight;

                const centerX = (screenWidth - blockWidth) / 2;
                const centerY = (screenHeight - blockHeight) / 2;

                this.setState({
                    cartPositionX: centerX,
                    cartPositionY: centerY,
                    isAnimating: false
                });

                setTimeout(() => {
                    const navWrapper = document.querySelector('.menu_nav-wrapper');
                    const rect = navWrapper.getBoundingClientRect();
                    this.setState({ cartPositionX: rect.x,  cartPositionY: rect.y  });
                }, 1800);

                console.log('tes')
            }
            

        }
    }

    render() {
        const { productName, showPopup } = this.props;
        const { cartPositionX, cartPositionY, isAnimating } = this.state;
        const popupClass = showPopup ? "popup show" : isAnimating ? "popup hide" : "popup";

        return (
            <div
                ref={ref => (this.block = ref)} // Ссылка на элемент блока
                className={popupClass}
                style={{ transform: `translate(${cartPositionX}px, ${cartPositionY}px)` }}>
                {productName.name} Added to cart!
            </div>
        );
    }
}

export default AddedToCartPopup;