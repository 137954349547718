import React, { Component } from 'react';
import './Cart.css'
import Navigation from '../Navigation/Navigation';


class Cart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cartData: JSON.parse(localStorage.getItem('cart')) || {},
            cartProductInfo: [],
            isLoading: true,
            amount: 0
        };
    }

    calculateTotalPrice = (cartData, cartProdData) => {

        if(!cartData) {
            cartData = this.state.cartData;
        }
        if(!cartProdData) {
            cartProdData = this.state.cartProdData;
        }
        // const { cartData, cartProdData } = this.state;
        let totalAmount = 0;
        console.log(cartData)
        console.log(cartProdData)
    
        Object.values(cartData).forEach((item) => {
            const product = cartProdData[item.productID];
            if (product && product.price && !isNaN(product.price.USD)) {
                const priceInUSD = parseFloat(product.price.USD);
                if (!isNaN(priceInUSD)) {
                    totalAmount += priceInUSD * item.quantity;
                }
            }
        });
    
        return totalAmount;
    };

    removeFromCart = (productID, size) => {
        const { cartData } = this.state;
        const updatedCart = { ...cartData };
        const keyCart = `${productID}-${size}`;
        
        delete updatedCart[keyCart];

        this.setState({ cartData: updatedCart }, () => {
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            const totalAmount = this.calculateTotalPrice(updatedCart, false);
            this.setState({ amount: totalAmount });
            document.dispatchEvent(new CustomEvent('cartUpdated'))
        });
    };

    componentDidMount() {
        const { cartData } = this.state;
        const keysArray = Object.keys(cartData).map((key) => key.split('-')[0]);
        const promises = Object.values(keysArray).map((item) => 
            fetch(`https://etheling.com/api/products/${item}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Ошибка при получении данных с сервера');
                    }
                    return response.json();
                })
                .then((data) => ({ [`${item}`]: data }))
                .catch((error) => {
                    console.error('Ошибка при получении данных с сервера', error);
                    return null;
                })
        );
    
        Promise.all(promises)
            .then((results) => {
                const successfulResults = results.filter((result) => result !== null);
                const cartProdData = Object.assign({}, ...successfulResults);
                this.setState({ cartProdData });
                this.setState({ isLoading: false });
    
                const totalAmount = this.calculateTotalPrice(false, cartProdData);
                this.setState({ amount: totalAmount });
            })
            .catch((error) => {
                console.error('Произошла ошибка при выполнении запросов', error);
            });
    }


    render() {
        if (this.state.isLoading) {
			
			return (
				<>
				Loading...
			</>
			)
		}
        const { cartData, cartProdData, amount } = this.state
        const isEmpty = Object.keys(cartData).length === 0;
        
        return (
        <div className='cart_page'>
            <Navigation/>
            <div className="cart_page_wrapper-items">
                {isEmpty ? (
                        <p className='emptyCart'>Empty</p>
                    ) : (
                        /* Здесь отображается содержимое корзины */
                        <>
                            {Object.values(cartData).map((item, index) => (
                                <div 
                                    className='cart_item'
                                    key={index}>
                                    <button
                                        className='cart_item_delete'
                                        onClick={() => this.removeFromCart(item.productID, item.size)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#5E4545" width="10px" height="10px" viewBox="0 0 16 16">
                                            <path d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z" fillRule="evenodd"/>
                                        </svg>
                                    </button>
                                    <div className="priceWrapper cart_item_price">
                                        {`${parseInt(cartProdData[item.productID].price.USD)} $`}
                                    </div>
                                    <div className="cart_item_name">
                                    {`${cartProdData[item.productID].name} (${item.size})`}
                                    </div>
                                    <div className="cart_item_image">
                                    <img src={cartProdData[item.productID].images[0].image} alt={cartProdData[item.productID].images[0].alt} />
                                    </div>
                                </div>
                            ))}
                            <div className="bottom-cart">
                                <div className="cart-amount priceWrapper">
                                    {amount} $
                                </div>
                                <a href="/checkout" className='cart_checkout addToCartBtn'>
                                proceed to checkout
                                </a>
                            </div>
                        </>
                    )}
            </div>
        </div>
        )
    }
}

export default Cart
