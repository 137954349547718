import React, { Component } from 'react';
import './Price.css';

class Price extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCurrency: 'USD',
            currentCurrencySymb: '$',
        };
    }

    render() {
        const { currentCurrency, currentCurrencySymb } = this.state;
        const price = this.props.product.price[currentCurrency]
        const old_price = this.props.product.price['old_USD']
        return (
            <div className="priceWrapper">
                {old_price && old_price !== 0 && (
                    <span className="oldPrice">{`${parseInt(old_price)} ${currentCurrencySymb}`}</span>
                )}
                {`${parseInt(price)} ${currentCurrencySymb}`}
            </div>
        )
    }
}

export default Price;