import React, { Component } from 'react';
import './ImageInfo.css';

class ImageInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isMobile: window.innerWidth <= 768
        };
    }

    updateDimensions = () => {
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    render() {
        const { isMobile } = this.state
        const { currentProduct, products } = this.props;
        const items = products[currentProduct].image_info.map((item, index) => (        
            <div key={index} className={`infoWrapper ${index === this.props.currentSlide ? 'active' : ''}`}>
                <div
                    className='infoWrapper_image'
                    style={{ backgroundImage: `url(${item.image})` }}></div>
                <div className='infoWrapper_text'>{item.text}</div>
            </div>
        ));

          
        return (
            <div className="description-section"> 
                {items}
            </div>
        );
    }
}


export default ImageInfo;