import React from 'react';
import Main from './components/Main/Main'
import About from './components/About/About';
import Gallery from './components/Gallery/Gallery';
import Cart from './components/Cart/Cart';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import Checkout from './components/Checkout/Checkout';
import Payment from './components/Payment/Payment';
import './App.css';
import  { Routes, Route } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import SuccessXendit from './components/SuccessXendit/SuccessXendit';
import FailXendit from './components/FailXendit/FailXendit';


function App() {
   const location = useLocation();
	useEffect(() => {
		// Список путей, где body должен иметь overflow: hidden
		const noOverflowRoutes = ['/', '/about'];
	
		// Проверяем, входит ли текущий путь в список noOverflowRoutes
		if (noOverflowRoutes.includes(location.pathname)) {
		  document.body.style.overflow = 'hidden';
		} else {
		  document.body.style.overflow = 'auto';
		}
	  }, [location]);

  return (
		<Routes>
			<Route exact path="/" element={<Main/>}/>
			<Route exact path="/cart" element={<Cart/>}/>
			<Route exact path="/checkout" element={<Checkout/>}/>
			<Route exact path="/gallery" element={<Gallery/>}/>
			<Route exact path="/about" element={<About/>}/>
                        <Route exact path="/success_xendit" element={<SuccessXendit/>}/>
			<Route exact path="/fails_xendit" element={<FailXendit/>}/>
			<Route exact path="/order" element={<Payment/>}/>
			<Route
				path="/terms_and_conditions"
				element={<TermsAndConditions page="terms" />}
				/>
			<Route
				path="/privacy_policy"
				element={<TermsAndConditions page="privacy" />}
				/>
			{/* <Route
				path="/terms_and_conditions"
				render={(props) => <TermsAndConditions page="privacy" {...props} />}
			/>
			<Route
				path="/privacy_policy"
				render={(props) => <TermsAndConditions page="privacy" {...props} />}
			/> */}
		</Routes>
  )
}

export default App;
