import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Gallery.css'
import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation';
import YouTube from 'react-youtube';

import { Mousewheel } from 'swiper/modules';


class Gallery extends Component {
    constructor(props) {
		super(props);
        this.video_url = ['Jn5CShIEOWc', 'IKoGvVi86Ro', 'Vi9jFAwXIxk', 'ssAGseD5V20', 'I5tmMJ8h_kg']
        this.state = {
            isLoading: true,
            currentVideo: this.video_url[0],
            screenHeight: window.innerHeight
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 500);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
      }
    
    handleResize = () => {
        this.setState({
            screenHeight: window.innerHeight,
        });
    };


    changeVideoHandler = (index) => {
        this.setState({
            currentVideo: this.video_url[index],
        });
    }

    render() {
        const { isLoading, currentVideo, screenHeight } = this.state;
        const videos = this.video_url;
        const screenHeightWoNav = screenHeight - 62;
        const desiredAspectRatio = 16 / 9;
        const videoHeight = screenHeightWoNav * 0.6;
        const videoWidth = videoHeight * desiredAspectRatio;
        const navigationHeight = screenHeightWoNav * 0.3;
        
        const opts = {
            height: videoHeight,
            width: videoWidth
        };

        return (
            <div className={`gallery-page ${isLoading? '' : 'hidden-page'}`}>
                <Navigation/>
                <div 
                    className='gallery-page_content'
                    style={{height: `calc(100vh - 62px)`}}
                    >
                    <YouTube
                        className='mainVideo'
                        style={{height: `${videoHeight}px`}}
                        videoId={currentVideo}
                        opts={opts}
                        />
                    <div
                        className='videoNavigation'
                        style={{height: `${navigationHeight}px`, width: `${videoWidth}px`}}>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            mousewheel={true}
                            modules={[Mousewheel]}
                            className="swiperNavVideo"
                        >
                            {videos.map((video, index) => (
                            <SwiperSlide
                                key={index}
                                className="itemVideoNavigation"
                                style={{ backgroundImage: `url(https://i.ytimg.com/vi/${video}/hqdefault.jpg)` }}
                                onClick={() => this.changeVideoHandler(index)}
                                >
                            </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        )
    }
}


export default Gallery