import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import CountryList from 'react-select-country-list';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './ShippingForm.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const ShippingForm = ({ amount, cartData }) => {
    const { control, handleSubmit, register, formState: { errors } } = useForm();
    const [orderID, setOrderID] = useState(null);
    const [error, setError] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState('us');
    const countryList = CountryList().getData();
    const [isLoading, setIsLoading] = useState(false);




    const onSubmit = async (data) => {
        setIsLoading(true);
        const productsArray = Object.values(cartData);
        const orderDetails = {
            ...data,
            products: productsArray
        };
        const csrftoken = getCookie('csrftoken');

        try {
            const response = await fetch('https://etheling.com/api/create_order/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify(orderDetails)
            });
            const json = await response.json();
            if (!response.ok) throw new Error(json.message || "Failed to create order");
            setOrderID(json.order_id);  // Сохраняем полученный orderID для использования в PayPal Buttons
        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    };

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption.value.toLowerCase());
    };

    function payByXendit(orderID) {
        // Функция для вызова при клике на кнопку
        const csrftoken = getCookie('csrftoken');
        return async (event) => {
            event.preventDefault();
            setIsLoading(true);
    
            try {
                // Отправка запроса на сервер для создания инвойса
                const response = await fetch('https://etheling.com/api/pay_via_xendit/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrftoken
                    },
                    body: JSON.stringify({
                        order_id: orderID
                    })
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
    
                // Перенаправление пользователя на URL для оплаты
                window.location.href = data.hosted_checkout_url;
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            } finally {
                setIsLoading(false); // Выключаем режим загрузки
            }
        };
    }

    const changeOrderStatus = async (orderID) => {
        const csrftoken = getCookie('csrftoken');
        try {
            const response = await fetch('https://etheling.com/api/change_order_status/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify({order_id: orderID})
            });
            const json = await response.json();
            window.location.href = 'https://etheling.com/order?orderid=' + orderID;
            if (!response.ok) throw new Error(json.message || "Failed to change order status");
            console.log("Order status changed successfully");
            window.location.href = 'https://etheling.com/order?orderid=' + orderID;
        } catch (error) {
            console.error("Failed to change order status:", error);
        }
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    }


    return (
        <form
        className='checkout_form checkout_shipping_form'
        onSubmit={handleSubmit(onSubmit)}>
        {!orderID ? (
            <div>
            <h2 className='checkout_title_form'>Shipping details</h2>
                <label className="checkout_form_item firstname_field">
                <span>First Name</span>
                <input {...register('firstName', { required: true })} placeholder="First Name" />
                {errors.firstName && <span className='error_field'>This field is required</span>}
            </label>
        
            <label className="checkout_form_item lastname_field">
                <span>Last Name</span>
                <input {...register('lastName', { required: true })} placeholder="Last Name" />
                {errors.lastName && <span className='error_field'>This field is required</span>}
            </label>

            <label className="checkout_form_item country_field">
                <span>Country</span>
                <Controller
                    name="country"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Select
                            options={countryList}
                            onChange={(selectedOption) => {
                                handleCountryChange(selectedOption)
                                field.onChange(selectedOption);
                            }}
                            value={field.value} // Убедитесь, что значение синхронизировано
                            isSearchable
                            placeholder="Select Country"
                        />
                    )}
                />
                {errors.country && <span className='error_field'>This field is required</span>}
            </label>
            

            <label className="checkout_form_item city_field">
                <span>City</span>
                <input {...register('city', { required: true })} placeholder="City" />
                {errors.city && <span className='error_field'>This field is required</span>}
            </label>
            
            
            <label className="checkout_form_item address_field">
                <span>Address</span>
                <input {...register('address', { required: true })} placeholder="Address" />
                {errors.address && <span className='error_field'>This field is required</span>}
            </label>
            
            <label className="checkout_form_item zipcode_field">
                <span>Zip Code</span>
                <input {...register('zipCode', { required: true })} placeholder="Zip Code" />
                {errors.zipCode && <span className='error_field'>This field is required</span>}
            </label>
            
            
            <label className="checkout_form_item email_field">
                <span>Email</span>
                <input {...register('email', { required: true })} placeholder="Email" />
                {errors.email && <span className='error_field'>This field is required</span>}
            </label>
            
            
            <label className="checkout_form_item phone_field">
                <span>Phone</span>
                <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <PhoneInput
                            {...field}
                            country={selectedCountry} 
                        />
                    )}
                />
                {errors.phoneNumber && <span className='error_field'>This field is required</span>}
            </label>
            </div>
        ): (
            <h2 className='checkout_title_form'>Payment method</h2>
        )}
            
        <div className="btn_wrapper">
            {!orderID && (
                <button type="submit" disabled={isLoading}>{isLoading ? 'Loading...' : 'Place Order'}</button>
            )}
           
            {error && <p>Error: {error}</p>}

            {orderID && (
               <div>
                <button className='payByXendit' onClick={payByXendit(orderID)} disabled={isLoading}>{isLoading ? 'Loading...' : 'Pay by card'}</button>
                <PayPalScriptProvider options={{ "client-id": "ASOrNo88xvwHtMSO0Jy7O7-18L-i7HOd6KNdn-dAIEwvlRnrPxVpLdQxOk7oVJadglImqR4snb4JwVuW", "currency": "USD" }}>
                    <PayPalButtons
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [{
                                    reference_id: orderID,
                                    amount: {
                                        currency_code: "USD",
                                        value: amount.toString(),
                                    }
                                }],
                            });
                        }}
                        onApprove={(data, actions) => actions.order.capture().then(details => {
                            localStorage.removeItem('cart');
                            console.log("Transaction completed by ");
                            if (orderID) {
                                changeOrderStatus(orderID);
                            }
                        })}
                    />
                </PayPalScriptProvider>
               </div>
            )}
            </div>
        </form>
    );
};

export default ShippingForm;
