import './Loader.css';
import React, { Component } from 'react';

class Loader extends Component {
	constructor(props) {
		let windowWidth = document.documentElement.clientWidth;
		let translateX = windowWidth/4
		super(props);
		this.state = {
			firstLoading: 0,
      isMobile: false,
      closedLoader: false
		  };
		this.translateX = translateX;
	}

  updateDimensions = () => {
    if(window.innerWidth < 991) {
      this.setState({ 
        isMobile: true
      });
    } else {
      this.setState({ 
        isMobile: false
      });
    }
    
  }

  setHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions);
    this.setHeight(); // Установить высоту при монтировании
    window.addEventListener('resize', this.setHeight); // Слушатели для изменений размеров
    window.addEventListener('orientationchange', this.setHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('resize', this.setHeight); // Очистить слушатели при размонтировании
    window.removeEventListener('orientationchange', this.setHeight);
  }


	
  clickHandlerLink = (e) => {
	this.setState({ firstLoading: 1 });
    e.currentTarget.classList.add('opacity');
    let leftLine = document.querySelector(".line-1");
    let rightLine = document.querySelector(".line-2");

    leftLine.style.transform = `translateX(-105%)`
    rightLine.style.transform = `translateX(105%)`
    
    const root = document.documentElement;
    root.style.setProperty('--left-translate', `${this.translateX}px`);

    let loader = document.querySelector(".loader");
    let logo = document.querySelector(".block__loader-link");
    loader.classList.add('opacity');
    logo.classList.add('hide');

    let home_page = document.querySelector(".home-page");
    let line4 = document.querySelector(".line-3");
    let line3 = document.querySelector(".line-4");
    setTimeout(() => {
      loader.style.display = 'none';
      if(this.state.isMobile) {
        home_page.classList.add('loader_closed');
        line4.style.display = 'none';
        line3.style.display = 'none';
      }
      
    }, 1500);
  }
  

  render() {

    const index = 80 / this.props.totalSlides
    const {isMobile} = this.state

    return (
      <>

        <div className='loader'>
          <div className='block__loader-link'>
            <button className={'loader-link'} onClick={this.clickHandlerLink}>Enter
              <img className='loader-link__img' src='img/logopravki_1.png' alt=""/>
            </button>
          </div>
          <div className="line-1 line">
            <div><div></div></div>
          </div>
          <div className="line-2 line">
            <div><div></div></div>
          </div>
        </div>
        <div
          className="line-3 line"
          style={
            this.state.firstLoading 
            ? isMobile 
              ? { transform: `translateY(-100vh)` }
              : { transform: `translate(${this.translateX}px, calc(-150px + ${index * this.props.currentSlide}vh))` }
            : {}
          }>
			
          <div><div></div></div>
        </div>
        <div
          className="line-4 line"
          style={
            this.state.firstLoading 
            ? isMobile 
              ? { transform: `translateY(100vh)` }
              : { transform: `translate(${this.translateX}px, calc(-380px + ${index * this.props.currentSlide}vh))` }
            : {}
          }>
          <div><div></div></div>
        </div>
      </>
    );
  }
}

export default Loader;


