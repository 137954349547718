import './Main.css'
import React, { Component } from 'react';
import Loader from '../Loader/Loader'
import Navigation from '../Navigation/Navigation';
import MainImages from '../MainImages/MainImages';
import ImageInfo from '../ImageInfo/ImageInfo';
import ChoseJacket from '../ChoseJacket/ChoseJacket';


class Main extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentSlide: 0,
			totalSlides: 0,
			currentProduct: 0,
			currentCategory: 'Man',
			products: [],
			isLoading: true,
		};
	}

	componentDidMount() {
		fetch('https://etheling.com/api/products/')
		  .then((response) => response.json())
		  .then((data) => {
			console.log(data);
			this.setState({ products: data,  isLoading: false  });
		  })
		  .catch((error) => {
			console.error('Ошибка при получении данных с сервера', error);
			this.setState({ isLoading: false });
		  });
	  }
	
	handleSlideChange = (newSlide) => {
		this.setState({ currentSlide: newSlide });
	}

	handleCategoryChange = (category) => {
		this.setState({
			currentCategory: category,
			currentSlide: 0,
			currentProduct: 0
		});
	}

	handleCurrentProductChange = (newCurrentProduct) => {
		this.setState({
			currentSlide: 0,
			currentProduct: newCurrentProduct
		});
	}
	
	render() {

		if (this.state.isLoading) {
			
			return (
				<>
				<Loader 
					currentSlide={0}
					totalSlides={0}
				/>
			</>
			)
		}
		const category = this.state.currentCategory;
		const idCurrentCategory = this.state.products.findIndex(item => item.category_name === category);
		const countSlides = this.state.products[idCurrentCategory]['products'][this.state.currentProduct].images.length
		const products = this.state.products[idCurrentCategory]['products'];
		return (
			<>
			<Loader 
				currentSlide={this.state.currentSlide}
				totalSlides={countSlides}
			/>
			<div className="home-page">
				<div className="image-section">
					<Navigation 
						currentCategory={category}
						categories={this.products}
						onCategoryChange={this.handleCategoryChange}
					/>
					<MainImages 
						currentSlide={this.state.currentSlide}
						totalSlides={countSlides}
						onSlideChange={this.handleSlideChange}
						// onTotalSlidesChange={this.handleTotalSlidesChange}
						currentProduct={this.state.currentProduct}
						products={products}
					/>
				</div>
				<ImageInfo 
						currentSlide={this.state.currentSlide}
						currentProduct={this.state.currentProduct}
						products={products}
						onSlideChange={this.handleSlideChange}
					/>
				<ChoseJacket
					onCurrentProductChange={this.handleCurrentProductChange}
					currentProduct={this.state.currentProduct}
					products={products}
				/>
			</div>
			</>
		)
	}
}

export default Main
