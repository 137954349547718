import React from 'react';
import Navigation from '../Navigation/Navigation';


const FailXendit = () => {

    return (
        <div className="payment_info">
            <Navigation />
            <div className="payment_content">
                <p>Payment failed, please contact us to resolve this issue.</p>
            </div>
        </div>
    )
}

export default FailXendit;
