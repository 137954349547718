import React, { Component } from 'react';
import './Menu.css'
import { LazyLoadImage } from "react-lazy-load-image-component";

class Menu extends Component {

    render() {
        return (
            <div className={`menu-wrapper ${this.props.isOpen ? 'open' : ''}`}>
                <div className='menu-content'>
                    <div className='LogoText'><a href='/'>{this.props.logoText}</a></div>
                    <div className='menu-links-wrapper'>
                        {/* <a 
                            href='/gallery'
                            className='menu-link-item'>
                            gallery
                        </a> */}
                        <a 
                            href='/about'
                            className='menu-link-item'>
                            about
                        </a>
                        <a 
                            href='/terms_and_conditions'
                            className='menu-link-item'>
                            read terms and conditions
                        </a>
                        <a 
                            href='/privacy_policy'
                            className='menu-link-item'>
                            read privacy policy
                        </a>
                    </div>
                    <LazyLoadImage
                        className='menu_logo_image'
                        src='img/logopravki_1.png'
                        width={657} height={634}
                        alt="Logo ETHELING"
                    />
                    <button className='closeMenu btn-link' onClick={this.props.onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20px" height="20px" viewBox="0 0 16 16">
                            <path d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z" fillRule="evenodd"/>
                        </svg>
                    </button>
                </div>
            </div>
        )
    }
}

export default Menu;