import React from 'react';
import Navigation from '../Navigation/Navigation';


const SuccessXendit = () => {

    return (
        <div className="payment_info">
            <Navigation />
            <div className="payment_content">
                <p>Thank you for your order. Your payment has been completed, and a receipt for your purchase has been emailed to you.</p>
            </div>
        </div>
    )
}

export default SuccessXendit;
