import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './ChoseJacket.css';
import { Mousewheel } from 'swiper/modules';
import SelectSize from '../SelectSize/SelectSize';
import AddToCart from '../AddToCart/AddToCart';
import Price from '../Price/Price';

class ChoseJacket extends Component {
  constructor(props) {
    super(props);
    this.swiper = null;
    this.state = {
      size: '',
      isMobile: window.innerWidth <= 768
    }
    this.handleSlideChange = this.handleSlideChange.bind(this);
    this.handleSlideClick = this.handleSlideClick.bind(this);
  }

  updateDimensions = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  handleSlideChange(swiper) {
    this.props.onCurrentProductChange(swiper.activeIndex);
  }

  handleSlideClick(index) {
    this.swiper.slideTo(index); // Перемещаемся к выбранному слайду
  }

  handleChangeSize = (newSize) => {
		this.setState({
			size: newSize
		});
	}

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    // Проверьте, изменилось ли значение currentProduct
    if (this.props.currentProduct !== prevProps.currentProduct) {
      // Измените активный слайд на новый индекс
      this.swiper.slideTo(this.props.currentProduct);
    }
  }

  render() {
    const { currentProduct, products } = this.props;
    const { size, isMobile } = this.state;
    const product = products[currentProduct];
    const productID = product.pk;

    return (
      <div className="brands-section">
      {isMobile ? ( 
          <Swiper
            direction="horizontal"
            slidesPerView={2.5}
            mousewheel={false}
            centeredSlides={true}
            className="item-wrapper"
            onSlideChange={this.handleSlideChange}
            onSwiper={(swiper) => (this.swiper = swiper)}
          >
            {products.map((product, index) => (
              <SwiperSlide
                key={index}
                className="item"
                onClick={() => this.handleSlideClick(index)} // Добавляем обработчик клика
              >
                {product.name}
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (

          <Swiper
          direction="vertical"
          slidesPerView={3}
          mousewheel={true}
          centeredSlides={true}
          modules={[Mousewheel]}
          className="item-wrapper"
          onSlideChange={this.handleSlideChange}
          onSwiper={(swiper) => (this.swiper = swiper)}
        >
          {products.map((product, index) => (
            <SwiperSlide
              key={index}
              className="item"
              onClick={() => this.handleSlideClick(index)} // Добавляем обработчик клика
            >
              {product.name}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
        <div className="btn-wrapper">
          <Price product={product} />
          <SelectSize 
            onChangeSize={this.handleChangeSize}
            product={product} />
          <AddToCart
            productID={productID}
            product={product}
            size={size}
            />
        </div>
      </div>
    );
  }
}

export default ChoseJacket;
