import './Navigation.css'
import React, { Component } from 'react';
import Menu from '../Menu/Menu';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isMenuOpen: false,
          logoText: 'ETHELING',
          cartCount: 0
        };
    
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    
    toggleMenu() {
        this.setState((prevState) => ({
            isMenuOpen: !prevState.isMenuOpen
        }));
    }

    closeMenu = () => {
        this.setState({
          isMenuOpen: false
        });
      };

    changeCategory = (category) => {
        this.props.onCategoryChange(category);
    }

    updateCartCount = () => {
        const cartData = JSON.parse(localStorage.getItem('cart')) || {};
        const itemCount = Object.keys(cartData).reduce((total, key) => {
          return total + Number(cartData[key].quantity);
        }, 0);
        this.setState({ cartCount: itemCount });
      };
    
      componentDidMount() {
        this.updateCartCount();
        window.addEventListener('storage', this.updateCartCount);
        document.addEventListener('cartUpdated', this.updateCartCount);
      }
    
      componentWillUnmount() {
        window.removeEventListener('storage', this.updateCartCount);
        document.removeEventListener('cartUpdated', this.updateCartCount);
      }
    
    

    render() {
        const { isMenuOpen, logoText, cartCount} = this.state;
        const currentCategory = this.props.currentCategory

        return (
            <>
            <div className="menu">
                <div className='LogoText'><a href='/'>{logoText}</a></div>
                <div className='ChangeCategories'>
                        <button
                            className={`choseCategory btn-link man ${currentCategory === 'Man' ? 'active' : ''}`}
                            onClick={() => this.changeCategory('Man')}>
                            man
                        </button>
                        <button 
                            className={`choseCategory btn-link woman ${currentCategory === 'Woman' ? 'active' : ''}`}
                            onClick={() => this.changeCategory('Woman')}>
                            woman
                        </button>
                    </div>
                <div className='menu_nav-wrapper'>
                    <button className='btn-link menu_btn' onClick={this.toggleMenu}>menu</button>
                    <a href='/cart'>cart 
                    {cartCount > 0 && <span className='itemCounter'>{cartCount}</span>}</a>
                </div>
                <Menu 
                    isOpen={isMenuOpen}
                    logoText={logoText}
                    onClose={this.closeMenu}
                    />
            </div>
            
            </>
        )
    }
}

export default Navigation
