import './TermsAndConditions.css'
import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation';

class TermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.page !== this.props.page) {
            this.setState({
            page: this.props.page,
            });
        }
    }

    handleTermsClick = () => {
        this.setState({
          page: 'terms', // Устанавливаем page в 'terms'
        });
      };
    
      handlePolicyClick = () => {
        this.setState({
          page: 'privacy', // Устанавливаем page в 'policy'
        });
      };
    

    render() {
        const { page } = this.state;
        return (
            <div className='info-page'>
            <Navigation/>
                <div className='info-page-navigation'>
                    <button
                        className={`${page === 'privacy' ? 'active': ''} `}
                        onClick={this.handlePolicyClick}>read privacy policy</button>
                    <button
                        className={`${page === 'terms' ? 'active': ''} `}
                        onClick={this.handleTermsClick}>read terms and conditions</button>
                </div>
                <div className='info-page-content'>
                    <div
                        style={{ transform: `translateX(-${page === 'terms' ? '100': '0'}%)` }}
                        className='privacyText'>
                        <p>WE RESPECT YOUR PRIVACY!</p>
                        <p>If you have any privacy related questions, please contact us at info@etheling.com.</p>
                        <p>ETHELING is a company registered in Indonesia with number AHU-014630.AH.01.30 and address 53144, Indonesia, Centrak Jawa, Purwokerto, Perum Puri Indah Blok I No 24, which collects and uses information when you use the website available at https://etheling.com/ (“website"), make purchases through the website or otherwise interact with it.</p>
                        <p>This privacy policy describes how we collect and use the personal information you provide.</p>
                        <p>This policy applies to people who use the website. For the purposes of this policy, we define the term "user", "you", "your", "you" as the person who interacted with the website.</p>
                        <p>ETHELING does not sell your personal data to third parties.</p>
                        <p>We adhere to the following principles to protect your privacy:</p>
                        <p>1) We only process personal data in a fair and transparent manner for specific and legitimate purposes;<br/>
                        2) We collect personal data only to the extent necessary to achieve certain purposes and do not store personal data if they are no longer needed;<br/>
                        3) We use personal data for other purposes only with the consent of the data subject or with the permission of the competent authority;<br/>
                        4) Personal data must be up-to-date, complete and necessary to achieve the purpose of data processing;<br/>
                        5) Using appropriate technical capabilities, security measures must be applied to protect personal data from unauthorized or unlawful processing, as well as from accidental loss or damage.</p>
                        <p>1.1. In order to fulfill our obligations and enable you to use our website and purchase products from it, we may ask you to provide us with your personal data, including:</p>
                        <p>1.1.1. Name;</p>
                        <p>1.1.2. Date of Birth;</p>
                        <p>1.1.3. Phone number;</p>
                        <p>1.1.4. E-mail address;</p>
                        <p>1.1.5. Other relevant data. This information is used by us to identify customers and provide them with goods and services;</p>
                        <p>1.2. Personal data collected or transmitted to ETHELING in the course of interaction and operation of the website may include, but is not limited to the following:</p>
                        <p>1.2.1. Server and purchase history information, which may include (but is not limited to) your registration details, date and time of visits, pages viewed, your IP address;</p>
                        <p>1.2.2. Information collected through cookies and other tracking technologies. We use various technologies to collect information when you interact with the website, including cookies and web beacons. "Cookies" are small data files that are stored on your device when you visit the website, which allows us to collect information about your device identifiers, IP address, web br/owsers used to access the website, pages viewed, as well as the time spent on the pages. Web beacons are graphic images placed on a website that are used to track a user visiting a website;</p>
                        <p>1.2.3. ETHELING may collect personal data from you in a variety of ways and under various circumstances, including but not limited to the purchase of goods;</p>
                        <p>2. USE OF COLLECTED PERSONAL DATA</p>
                        <p>2.1 We may use the information we collect from you for a variety of purposes, primarily related to the purchase and delivery of goods. We may also use the information for other purposes as permitted by law. For example, we may use your personal information for the following purposes:</p>
                        <p>2.1.1. To provide access to the site and the ability to purchase goods;</p>
                        <p>2.1.2. To process your orders and inquiries, including requests for technical support and assistance;</p>
                        <p>2.1.3. To fulfill tax and accounting obligations established by law;</p>
                        <p>2.1.4. To detect, investigate and prevent illegal activities that may violate the Website Terms of Use and this Privacy Policy;</p>
                        <p>2.1.5. To conduct internal research and development, and to improve, test and expand the features and functionality of our website;</p>
                        <p>2.1.6. To promote our website or related products;</p>
                        <p>2.1.7. To meet internal and external audit requirements, including our information security obligations;</p>
                        <p>2.1.8. To track purchases;</p>
                        <p>2.1.9. For any other purposes that we determine in our sole discretion as necessary or required to maintain the security and/or integrity of our users, employees, third parties, the public and/or our website;</p>
                        <p>3. CONSENT TO PROCESSING OF PERSONAL DATA</p>
                        <p>3.1. Consent of persons from the EU to the processing of personal data:</p>
                        <p>3.1.1. If you are an EU citizen and in order to process your personal data we need to obtain your consent as required by the GDPR, we will only process your personal data if we have received from you a specific, informed and unambiguous indication of your wishes, which you express consent to the processing of your personal data (“consent”);</p>
                        <p>3.1.2. You can give your consent by checking the box when purchasing a product through the website. If you check the appropriate box, you irrevocably agree that ETHELING has the right to process your personal data;</p>
                        <p>3.1.3. You have the right to withdraw your consent at any time. You can send an email request to info@etheling.com. Your withdrawal of consent does not affect the lawfulness of the processing of your personal data based on consent prior to its withdrawal.</p>
                        <p>3.1.5. If you withdraw your consent, you accept that we may continue to use the personal data previously provided to us to the extent that we are contractually or otherwise legally required to do so, as necessary to enforce any contractual obligation.</p>
                        <p>3.2. Consent of non-EU persons to the processing of personal data:</p>
                        <p>3.2.1. If you are a non-EU person, by submitting your personal data to us through the website or otherwise, you irrevocably agree that ETHELING has the right under this policy to:</p>
                        <p>3.2.1.1. Process in any way, including collection, storage, disclosure, sharing and transfer of your personal data collected as a result of your use of the website, as well as;</p>
                        <p>3.2.1.2. Use cookies and web beacons (pixel tags) to collect your personal data;</p>
                        <p>4. DATA ACCESS, DATA CORRECTION AND WITHDRAWAL OF CONSENT</p>
                        <p>4.1. You can view, correct and update your personal information. To do this, contact us directly at info@etheling.com. We will confirm your request within 72 (seventy-two) hours and process it quickly, in accordance with legal requirements;</p>
                        <p>4. 1. 1. Right of access. You can contact us to obtain information about whether we process your personal data;</p>
                        <p>4. 1. 2. Right to withdraw consent. You may withdraw your consent at any time by sending a request to info@etheling.com and your personal data will be deleted within 48 business hours. Withdrawal of consent may result in a reduction in your ability to use our website;</p>
                        <p>4.2. We store personal data for as long as it is necessary to provide any services available on our website. When personal data is no longer needed, we delete it using reasonable measures to protect the data from unauthorized access or use;</p>
                        <p>4.3. We strive to anonymize data whenever possible. If you choose to exercise your right to erasure, we will also notify our vendors to delete all of your data;</p>
                        <p>5. INFORMATION SECURITY</p>
                        <p>5.1. We care about the security of personal data. We follow generally accepted industry standards to protect the information provided to us, both during transmission and once we receive it. We take technical, physical and administrative security measures to ensure that your personal information is reasonably protected. When we or our contractors process your information, we also secure it from unauthorized access, loss, falsification, destruction or unauthorized disclosure. This is done through appropriate administrative, technical and physical measures;</p>
                        <p>5.2. There is no 100% secure method of transmission over the Internet, so we cannot guarantee absolute security;</p>
                        <p>5.3. We never process any criminal offense data. Also, we never do any profiling of personal data;</p>
                        <p>6. ACCEPTANCE OF THESE TERMS</p>
                        <p>6.1. We assume that all users have carefully read this document and agree to its contents. If anyone does not agree with this privacy policy, they should refrain from using the website;</p>
                        <p>7. CHANGES AND UPDATES TO OUR PRIVACY POLICY</p>
                        <p>7.1. We may update this privacy policy from time to time. We encourage you to review this policy periodically so that you are always aware of what information we collect and how we use it.</p>
                    </div>
                    <div 
                        style={{ transform: `translateX(-${page === 'terms' ? '100': '0'}%)` }}
                        className='termsText'>
                        <p>Welcome to the ETELING online store (Legal address: 53144, Indonesia, Centrak Jawa, Purwokerto, Perum Puri Indah Blok I No 24; Registration number: AHU-014630.AH.01.30.) (hereinafter referred to as "we", "us", "our" website). These terms and conditions govern your use of the etheling.com website and any options available on it provided by our company. These terms and conditions constitute a binding legal agreement between you and ETHELING. By registering or making purchases using a computer or mobile device, you acknowledge that you have read, understood and agree to be bound by these terms and conditions, as well as the privacy policy and cookie policy, which are included in this document (hereinafter referred to as the "agreement") through links.<br/>
                        We may change these terms and conditions at any time without notice. The terms come into force from the moment they are published on the website. Your continued use of the ETHELING online store will be deemed your acceptance of the revised terms and conditions. If you do not agree to these terms, please do not use the website.</p>
                            <p>1. TERMS AND DEFINITIONS</p>
                            <p>1.1. The following terms have the following meanings:</p>
                            <p>1.1.1. “Order/orders” means any order of goods provided by our company through the website;</p>
                            <p>1.1.2. “User” (or “you”, “your”) means an individual who has reached the age of 18 (eighteen) years (or such other age as, in accordance with their national law, gives the user the right to enter into legally binding agreements) accepting this agreement;</p>
                            <p>1.1.3. "Website" means the https://etheling.com/ website;</p>
                            <p>1.1.4. "Privacy Policy" - a set of rules governing the processing of personal data and privacy protection policies applicable to users;</p>
                            <p>2. SUBJECT OF THE CASE</p>
                            <p>2.1. By using our website, the user may purchase certain products;</p>
                            <p>2.2. You may not use our website for any illegal purpose;</p>
                            <p>3. ACCEPTABILITY</p>
                            <p>3.1. You may not purchase products through the website unless all of the following conditions are met:</p>
                            <p>3.1.1. You are at least 18 (eighteen) years of age;</p>
                            <p>3.1.2. You have entered into a legally binding contract with ETHELING;</p>
                            <p>3.1.3. You do not violate AML/CFT laws;</p>
                            <p>3.1.4. You agree to comply with this agreement and all applicable local, state, national and international laws, rules and regulations;</p>
                            <p>4. INTELLECTUAL PROPERTY</p>
                            <p>4.1. All intellectual property in the ETHELING online store, which includes material protected by copyright, trademark or patent laws, is either owned by us or licensed to us;</p>
                            <p>4.2. All trademarks, service marks, trade names and other identifiers are owned by us, registered and/or licensed by us;</p>
                            <p>4.3. All rights reserved;</p>
                            <p>5. PURCHASE OF GOODS</p>
                            <p>5.1. We use a third party payment processor to bill and charge you through your billing account. Payment processing is governed by the terms and privacy policy of the respective third party payment processor. The accepted currency for payment is the US dollar;</p>
                            <p>5.2. We do not control and are not responsible for the security or performance of the payment system;</p>
                            <p>6. DELIVERY OF GOODS</p>
                            <p>6.1. By purchasing a product, you agree to the direct delivery of the product;</p>
                            <p>6.2. We cooperate with a third-party transport company to deliver the goods. Conditions, terms and rules of delivery are formed by a third-party company;</p>
                            <p>6.3. We do not control and are not responsible for the goods after it has been handed over to the delivery service;</p>
                            <p>7. CHANGES TO ORDER AND REQUEST FOR REFUND</p>
                            <p>7.1. You waive claims for a return during the period of transportation of the goods to the address indicated by you;</p>
                            <p>7.2. You can send a return request to our email address info@etheling.com;</p>
                            <p>7.3. You can request a return of the product no later than 7 (seven) days after receiving it;</p>
                            <p>7.4. Your eligibility for a refund will be determined by ETHELING in its sole discretion;</p>
                            <p>7.5. We can only decide on your refund request when we are satisfied with the following information, which you must provide:</p>
                            <p>7.6.1. The purchased goods were not damaged or soiled due to the fault of the buyer;</p>
                            <p>7.7.2. There are no signs of fraud;</p>
                            <p>7.8. We can only refund you when you return the item back to us;</p>
                            <p>7.9. You are solely responsible for shipping the item in the event of a return;</p>
                            <p>8. LINKS</p>
                            <p>8.1. By using our website, you may follow various links to third party websites and applications operated by other companies ("third party services"). We do not monitor or control third party services, which have their own terms and conditions, privacy policy, and cookie policy. We are not responsible for the content of third party services;</p>
                            <p>9. LIMITATION OF LIABILITY</p>
                            <p>9.1. You understand and agree that the website and the products offered on the website are provided on an "AS IS" and "AS AVAILABLE" basis; this means that we do not take responsibility for the fact that the information about the products offered on the website meets your personal expectations, and we are not responsible for the reliability and accuracy of the information about the product from other sources;</p>
                            <p>9.2. Your use of any aspect of the website is at your own risk. We do not accept any responsibility for any actions you may take while using the website;</p>
                            <p>9.3. The Website may not be available in all languages or in all countries, and some products may not be available for purchase in your country or region;</p>
                            <p>9.4. You are solely responsible for all your orders, whether they were made by accident or not, as well as for your actions with paid and delivered goods. We are not responsible if, when making a purchase, you provided false information or purchased the product by accident;</p>
                            <p>9.5. You understand and accept that any transactions on the website may be disrupted due to technical failures, defects or problems caused by the network provider;</p>
                            <p>9.6. To avoid fraud of any kind, please pay attention to the sources you use. We provide our products and other company information only through our official resources available on the website;</p>
                            <p>10. PROCEDURE FOR REVIEWING COMPLAINTS</p>
                            <p>10.1. Any claims for work performed, indicating the reasons, must be brought to the attention of ETHELING at the email address info@etheling.com within 7 (seven) days from the date of the claim or within 7 (seven) days after delivery of the goods. We will endeavor to respond within 48 (forty-eight) hours (on business days). The completed work is considered accepted after the expiration of the above deadlines;</p>
                            <p>11. FORCE MAJOR</p>
                            <p>11.1. We are not obliged to fulfill any obligations towards the user if we are prevented from doing so by circumstances that cannot be attributed to anyone's fault and for which we are not responsible by law or regulation;</p>
                            <p>11.2. We reserve the right to suspend our obligations under this agreement for the duration of the force majeure event;</p>
                            <p>11.3. Upon our notice of a force majeure event, you have the right to cancel an order in writing;</p>
                            <p>12. NOTIFICATIONS AND ELECTRONIC MESSAGES</p>
                            <p>12.1. Any notice or other communication under these terms and conditions must be in writing and shall be deemed given and received when sent by email. The language of communication must be English;</p>
                            <p>12.2. Notices regarding these terms and conditions may be sent to us by email at info@etheling.com.</p>
                    </div>
                </div>
            </div>
        )
    }
}


export default TermsAndConditions
