import './About.css'
import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation';
import AboutText from '../AboutText/AboutText';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 500);
        
    }

    render() {
        const { isLoading } = this.state;
        return (
            <div className={`about-page ${isLoading? '' : 'hidden-page'}`}>
                <Navigation/>
                <div className='about-page_content'>
                <AboutText/>
                </div>
            </div>
        )
    }
}


export default About