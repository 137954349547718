import React, { Component } from 'react';
import './AddToCart.css';
import AddedToCartPopup from '../AddedToCartPopup/AddedToCartPopup';

class AddToCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addToCartText: 'Add to Cart',
            showPopup: false
        };
    }
    componentDidMount() {
        const cartData = JSON.parse(localStorage.getItem('cart')) || {};
        this.setState({ cartData });
    }

    addToCart = () => {
        const { productID, size } = this.props;
        const { cartData } = this.state;

        if (!size) {
            this.sizeDontChooseAlert();
            return;
        }

        // Генерация уникального ключа для каждого товара в корзине
        const cartItemId = `${productID}-${size}`;

        // Проверка наличия товара в корзине
        if (!cartData[cartItemId]) {
            cartData[cartItemId] = {
                productID,
                size,
                quantity: 1,
            };
        }

        // Сохранение данных корзины в localStorage
        localStorage.setItem('cart', JSON.stringify(cartData));

        // Обновление состояния корзины в вашем приложении
        this.setState({ cartData });
        const event = new CustomEvent('cartUpdated');
        document.dispatchEvent(event);

        this.setState({ showPopup: true });
        setTimeout(() => {
            this.setState({ showPopup: false });
        }, 2000);

    };

    sizeDontChooseAlert = () => {
        const selectWrapper = document.querySelector('.chosen_size');
        selectWrapper.classList.add("alert");

        setTimeout(() => {
            selectWrapper.classList.remove("alert");
        }, 1000);
    }

    render() {
        const { addToCartText } = this.state;

        return (
            <>
                <button className="addToCartBtn" onClick={this.addToCart}>
                    {addToCartText}
                </button>
                <AddedToCartPopup
                    productName={this.props.product}
                    showPopup={this.state.showPopup}
                />
            </>
            
        );
    }
}

export default AddToCart;