import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Payment.css';
import Navigation from '../Navigation/Navigation';


function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}


const Payment = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [orderStatus, setOrderStatus] = useState(null);
    const [trackingNumber, setTrackingNumber] = useState(null);
    const [trackingUrl, setTrackingUrl] = useState(null);
    const location = useLocation();
    const csrftoken = getCookie('csrftoken');

    useEffect(() => {
        async function fetchOrderStatus() {
            const urlParams = new URLSearchParams(location.search);
            const orderid = urlParams.get('paymentId');
    
            if (orderid) {
                try {
                    const response = await fetch('/api/get_payment_status/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': csrftoken
                        },
                        body: JSON.stringify({
                            OrderId: orderid
                        })
                    });
    
                    const data = await response.json();

                    console.log(data)
    
                    if (data.State === "Charged") {
                        setOrderStatus("Paid");
                    } else {
                        console.log("Error getting order status:", data.ErrCode);
                    }


                    if ('tracking_number' in data) {
                        setTrackingNumber(data.tracking_number);
                    } else {
                        console.log('Ключ "tracking_number" отсутствует в объекте.');
                    }


                    if ('tracking_url' in data) {
                        setTrackingUrl(data.tracking_url);
                    } else {
                        console.log('Ключ "tracking_url" отсутствует в объекте.');
                    }
    
                } catch (error) {
                    console.error("Failed to fetch order status:", error);
                }
            }
    
            setIsLoading(false);
        }
        fetchOrderStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="payment_info">
            <Navigation />
            <div className="payment_content">
                {orderStatus && <p>Status: {orderStatus}</p>}
                {trackingNumber && <p>Track Number in DHL: {trackingNumber}</p>}
                {trackingUrl && (
                    <p>
                        Tracking Url: <a href={trackingUrl} target="_blank" rel="noreferrer">{trackingUrl}</a>
                    </p>
                )}
            </div>
        </div>
    )
}

export default Payment;
