import React, { Component } from 'react';
import './SelectSize.css';

class SelectSize extends Component {
    constructor(props) {
        super(props);
        this.sizes = this.props.product.sizes;
        this.state = {
            isSelectOpen: false,
            sizeOptionsHeight: 0,
            chosenSize: ''
        };
        this.sizeWrapper = React.createRef();
        this.sizeOptions = React.createRef();
        this.handleSelectClick = this.handleSelectClick.bind(this);
        this.handleGlobalClick = this.handleGlobalClick.bind(this);
    }

    handleSelectClick() {
        this.setState((prevState) => ({
            isSelectOpen: !prevState.isSelectOpen,
            sizeOptionsHeight: this.sizeOptions.current.clientHeight
        }));
    }

    handleGlobalClick(event) {
        const selectElement = document.querySelector(".chose_size-wrapper");
        if (selectElement && !selectElement.contains(event.target)) {
          this.setState({
            isSelectOpen: false
          });
        }
    }

    handleChoseSize = (event) => {
        const chosenSize = event.target.textContent;
        this.setState({
            chosenSize,
            isSelectOpen: false,
        });
        this.props.onChangeSize(chosenSize);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleGlobalClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleGlobalClick);
    }


    render() {
        const { isSelectOpen, sizeOptionsHeight, chosenSize} = this.state;
        const { product } = this.props


        return (
            <div
                ref={this.sizeWrapper}
                className={`chose_size-wrapper ${isSelectOpen ? 'open' : ''}`}
                style={{ transform: `translateY(${isSelectOpen ? -sizeOptionsHeight + 'px' : '0'})` }}
                >
                <div className='chosen_size' onClick={this.handleSelectClick}>
                    {chosenSize === '' ? 'size' : chosenSize}
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
                        <path d="M1 1L5.5 6L10 1" stroke="#5A505B" strokeWidth="2"/>
                    </svg>
                </div>
                <div
                    ref={this.sizeOptions}
                    className='choosen_size-options'>
                    {product.variations.map((size, index) => (
                        <div
                            key={index}
                            className='choosen_size-options_item'
                            onClick={this.handleChoseSize}>
                            {size.size}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default SelectSize;