import React, { Component } from 'react';
import './MainImages.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';

class MainImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolling: false,
      isMobile: window.innerWidth <= 768
    };
  }

  updateDimensions = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    const sliderContainer = document.querySelector('.slider-container');
    sliderContainer.addEventListener('wheel', this.handleScroll);

    const infoContainer = document.querySelector('.description-section');
    infoContainer.addEventListener('wheel', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    const sliderContainer = document.querySelector('.slider-container');
    sliderContainer.removeEventListener('wheel', this.handleScroll);

    const infoContainer = document.querySelector('.description-section');
    infoContainer.addEventListener('wheel', this.handleScroll);
  }

  handleScroll = (e) => {
    if(!this.state.isMobile) {
      if (this.state.isScrolling) return;
      const delta = e.deltaY;
      const { currentSlide, currentProduct, products } = this.props;

      if (delta > 0 && currentSlide < products[currentProduct].images.length - 1) {
        this.setState({ isScrolling: true });
        this.props.onSlideChange(currentSlide + 1);
        setTimeout(() => {
          this.setState({ isScrolling: false });
        }, 1000);
      } else if (delta < 0 && currentSlide > 0) {
        this.setState({ isScrolling: true });
        this.props.onSlideChange(currentSlide - 1);
        setTimeout(() => {
          this.setState({ isScrolling: false });
        }, 1000);
      }
    }

  };

  render() {
    const { currentSlide, currentProduct, products } = this.props;
    const { isMobile } = this.state
    return (
      <div className="slider-container">

        {isMobile ? ( 
          <Swiper
            centeredSlides={true}
            slidesPerView={'auto'}
            spaceBetween={0}
            coverflowEffect={{
              rotate: 0,
              stretch: -120,
              depth: 1200,
              modifier: 2,
              slideShadows: false,
              scale: 1,
            }}
            onSlideChange={(swiper) => {
                if (swiper.activeIndex !== currentSlide) {
                    this.props.onSlideChange(swiper.activeIndex);
                }
            }}
            modules={[EffectCoverflow]}
            className="item-wrapper"
            effect="coverflow"
        >
            {products[currentProduct].images.map((image, index) => (
                <SwiperSlide
                    key={index}
                    className="item"
                >
                    <img src={image.image} alt="" />
                </SwiperSlide>
            ))}
        </Swiper>
        ) : (
          <div className="slider" style={{ transform: `translateY(-${currentSlide * 100}vh)` }}>
            {products[currentProduct].images.map((image, index) => (
              <div
                key={index}
                className={`slide ${index === currentSlide ? 'active' : ''}`}
                style={{ backgroundImage: `url(${image.image})` }}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default MainImages;
